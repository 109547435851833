import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from './firebaseConfig';

export const useChains = (videoId) => {
    const [chains, setChains] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const q = query(
            collection(db, 'Chains'),
            where('videoId', '==', videoId),
            orderBy('createdAt', 'desc')
        );

        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                const chainsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setChains(chainsData);
                setLoading(false);
            },
            (err) => {
                console.error("Error fetching chains: ", err);
                setError(err);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, [videoId]);

    return { chains, loading, error };
};