// videoService.js

import { collection, query, where, orderBy, limit, getDocs, doc, setDoc, writeBatch, startAfter } from 'firebase/firestore';
import { db } from './firebaseConfig';

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
const API_ERROR_COOLDOWN = 5 * 60 * 1000; // 5 minutes
const VIDEOS_PER_PAGE = 10;

const getFromLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const canMakeAPICall = (userId) => {
    const lastErrorTime = getFromLocalStorage(`youtube_api_error_${userId}`);
    return !lastErrorTime || (Date.now() - lastErrorTime > API_ERROR_COOLDOWN);
};

const setAPIErrorTime = (userId) => {
    setToLocalStorage(`youtube_api_error_${userId}`, Date.now());
};

const fetchFromYouTubeAPI = async (token, channelId, pageToken = '') => {
    const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&maxResults=${VIDEOS_PER_PAGE}&order=date&type=video${pageToken ? `&pageToken=${pageToken}` : ''}`;

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
};

const processVideos = (items) => {
    return items.map(item => ({
        id: item.id.videoId,
        title: item.snippet.title,
        description: item.snippet.description,
        thumbnailUrl: item.snippet.thumbnails.medium.url,
        publishedAt: item.snippet.publishedAt,
        channelId: item.snippet.channelId,
    }));
};

export const fetchVideos = async (userId, channelId, token, pageParam = 0) => {
    try {
        const cacheKey = `videos_${userId}_${channelId}`;
        let cachedData = getFromLocalStorage(cacheKey);

        // If we have cached data and it's for the requested page, return it
        if (cachedData && cachedData.videos.length > pageParam * VIDEOS_PER_PAGE) {
            console.log('Returning videos from local storage');
            return {
                videos: cachedData.videos.slice(pageParam * VIDEOS_PER_PAGE, (pageParam + 1) * VIDEOS_PER_PAGE),
                nextPageParam: (pageParam + 1) * VIDEOS_PER_PAGE < cachedData.videos.length ? pageParam + 1 : null
            };
        }

        // If we don't have cached data or need more, fetch from Firestore
        let firestoreQuery = query(
            collection(db, 'Videos'),
            where('channelId', '==', channelId),
            orderBy('publishedAt', 'desc'),
            limit(VIDEOS_PER_PAGE)
        );

        // If we're not on the first page, use startAfter
        if (pageParam > 0 && cachedData && cachedData.videos.length > 0) {
            const lastVideo = cachedData.videos[cachedData.videos.length - 1];
            firestoreQuery = query(firestoreQuery, startAfter(lastVideo.publishedAt));
        }

        const querySnapshot = await getDocs(firestoreQuery);
        let videos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // If we don't have enough videos or they're too old, fetch from YouTube API
        if (videos.length < VIDEOS_PER_PAGE || Date.now() - new Date(videos[0].publishedAt).getTime() > CACHE_DURATION) {
            if (canMakeAPICall(userId)) {
                console.log('Fetching videos from YouTube API');
                const data = await fetchFromYouTubeAPI(token, channelId, pageParam > 0 ? cachedData?.nextPageToken : '');
                const newVideos = processVideos(data.items);

                // Update Firestore
                await updateFirestore(userId, channelId, newVideos);

                // Update local storage
                if (cachedData) {
                    cachedData.videos = [...cachedData.videos, ...newVideos];
                    cachedData.nextPageToken = data.nextPageToken;
                } else {
                    cachedData = { videos: newVideos, nextPageToken: data.nextPageToken, timestamp: Date.now() };
                }
                setToLocalStorage(cacheKey, cachedData);

                videos = newVideos;
            } else {
                console.log('Using potentially outdated data due to API limitation');
            }
        }

        return {
            videos,
            nextPageParam: videos.length === VIDEOS_PER_PAGE ? pageParam + 1 : null
        };
    } catch (error) {
        console.error("Error fetching videos: ", error);
        setAPIErrorTime(userId);
        throw error;
    }
};

const updateFirestore = async (userId, channelId, videos) => {
    const batch = writeBatch(db);
    videos.forEach(video => {
        const videoRef = doc(db, 'Videos', video.id);
        batch.set(videoRef, { ...video, userId, channelId }, { merge: true });
    });
    await batch.commit();
};

export const refreshVideos = async (userId, channelId, token) => {
    const cacheKey = `videos_${userId}_${channelId}`;
    localStorage.removeItem(cacheKey);
    return fetchVideos(userId, channelId, token);
};