import React, {useState, useCallback, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useChannels } from './useChannels';
import { useVideos } from './useVideos';
import ResponsiveNavigation from './ResponsiveNavigation';
import VideoGrid from './VideoGrid';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHomeFeed} from "./useHomeFeed";

const HomePage = () => {
    //const { user, loading, signIn, signOutUser } = useAuth();
    const { user, token, loading, signIn, signOutUser } = useAuth();
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [loadingMoreChannels, setLoadingMoreChannels] = useState(false);
    const [loadMoreChannelsError, setLoadMoreChannelsError] = useState(null);
    const [isHomeFeed, setIsHomeFeed] = useState(true);
    const navigate = useNavigate();

    const {
        data: channels,
        isLoading: isLoadingChannels,
        refresh: refreshChannels,
        loadMore: loadMoreChannels
    } = useChannels(user?.uid, token);


    const {
        data: videosData,
        isLoading: isLoadingVideos,
        fetchNextPage,
        hasNextPage,
        refresh: refreshVideos,
        error: videosError
    } = useVideos(user?.uid, selectedChannel?.id, token);

    const {
        data: homeFeedVideos,
        isLoading: isLoadingHomeFeed,
        error: homeFeedError
    } = useHomeFeed(user?.uid, channels);

    const loadMore = useCallback(() => {
        console.log("loadMore called");
        if (hasNextPage && fetchNextPage) {
            console.log("Fetching next page");
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    // Add scroll event listener for debugging
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.innerHeight + window.scrollY;
            const documentHeight = document.documentElement.offsetHeight;
            const scrollPercentage = (scrollPosition / documentHeight) * 100;

            console.log(`Scroll position: ${scrollPercentage.toFixed(2)}%`);

            if (scrollPercentage > 80) {
                console.log("Nearing the bottom of the page");
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleHomeClick = () => {
        setSelectedChannel(null);
        setIsHomeFeed(true);
    };

    const handleChainsClick = () => {
        navigate('/chains');
        setIsHomeFeed(false);

    };

    const handleReflectionsClick = () => {
        navigate('/reflections');
        setIsHomeFeed(false);

    };

    const handleChannelClick = (channel) => {
        setSelectedChannel(channel);
        setIsHomeFeed(false);
    };

    const handleRefresh = async () => {
        await refreshChannels();
        if (selectedChannel) {
            await refreshVideos();
        }
    };

    const handleLoadMoreChannels = async () => {
        setLoadingMoreChannels(true);
        setLoadMoreChannelsError(null);
        try {
            await loadMoreChannels();
        } catch (error) {
            console.error("Error loading more channels:", error);
            setLoadMoreChannelsError("Failed to load more channels. Please try again.");
        } finally {
            setLoadingMoreChannels(false);
        }
    };



    const videos = isHomeFeed
        ? homeFeedVideos || []
        : (videosData ? videosData.pages.flatMap(page => page.videos || []) : []);

    const isLoading = loading || isLoadingChannels || (isHomeFeed ? isLoadingHomeFeed : isLoadingVideos);
    const error = isHomeFeed ? homeFeedError : videosError;


    // Add this logging
    console.log("HomePage videosData:", videosData);

    console.log("HomePage render - videos count:", videos.length);
    console.log("HomePage render - hasNextPage:", hasNextPage);

    if (loading || isLoadingChannels) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            <ResponsiveNavigation
                user={user}
                channels={channels || []}
                selectedChannel={selectedChannel}
                onChannelClick={handleChannelClick}
                onSignIn={signIn}
                onSignOut={signOutUser}
                onHomeClick={handleHomeClick}
                onChainsClick={handleChainsClick}
                onReflectionsClick={handleReflectionsClick}
                onRefresh={handleRefresh}
                onLoadMoreChannels={handleLoadMoreChannels}
                isLoadingMoreChannels={loadingMoreChannels}
                loadMoreChannelsError={loadMoreChannelsError}
                isHomeFeed={isHomeFeed}
            />
            <main className="flex-1 overflow-hidden">
                <InfiniteScroll
                    dataLength={videos.length}
                    next={loadMore}
                    hasMore={hasNextPage}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    <div id="scrollableDiv" className="h-screen overflow-auto">
                        <VideoGrid
                            videos={videos}
                            selectedChannel={selectedChannel}
                            onVideoClick={(video) => navigate(`/video/${video.id}`)}
                            isLoading={isLoading}
                            error={error?.message}
                        />
                    </div>
                </InfiniteScroll>
            </main>
        </div>
    );
};

export default HomePage;