import React, { useState, useEffect } from 'react';
import { Home, Link as ChainIcon, BookOpen, Menu, ChevronDown, ChevronUp, LogIn, LogOut } from 'lucide-react';

const ResponsiveNavigation = ({
                                  user,
                                  channels,
                                  selectedChannel,
                                  onChannelClick,
                                  onSignIn,
                                  onSignOut,
                                  onHomeClick,
                                  onChainsClick,
                                  onReflectionsClick,
                                  onLoadMoreChannels,
                                  isLoadingMoreChannels,
                                  loadMoreChannelsError,
                                  isHomeFeed
                              }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showAllSubscriptions, setShowAllSubscriptions] = useState(false);

    const INITIAL_FETCH_COUNT = 5;

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navItems = [
        { name: 'Home', icon: Home, onClick: onHomeClick },
        { name: 'Chains', icon: ChainIcon, onClick: onChainsClick },
        { name: 'Reflections', icon: BookOpen, onClick: onReflectionsClick },
    ];

    const getThumbnailUrl = (subscription) => {
        return subscription.thumbnailDefaultUrl || 'https://via.placeholder.com/36';
    };

    const handleLoadMore = async () => {
        if (isLoadingMoreChannels) return;
        await onLoadMoreChannels();
        setShowAllSubscriptions(true);
    };

    const visibleSubscriptions = showAllSubscriptions ? channels : channels.slice(0, INITIAL_FETCH_COUNT);

    const NavContent = () => (
        <>
            {navItems.map((item) => (
                <button
                    key={item.name}
                    onClick={() => {
                        item.onClick();
                        if (isMobile) setIsMenuOpen(false);
                    }}
                    className={`flex items-center p-2 rounded-lg text-gray-300 hover:bg-gray-700 w-full ${
                        item.name === 'Home' && isHomeFeed ? 'bg-gray-700' : ''
                    }`}
                >
                    <item.icon className="w-6 h-6 mr-2" />
                    <span className="text-sm">{item.name}</span>
                </button>
            ))}
            {user && (
                <div className="mt-4 border-t border-gray-700 pt-4">
                    <h3 className="text-sm font-semibold text-gray-400 uppercase mb-2">Subscriptions</h3>
                    {visibleSubscriptions.map((sub) => (
                        <div
                            key={sub.id}
                            className={`flex items-center mb-2 p-2 rounded cursor-pointer ${
                                selectedChannel && selectedChannel.id === sub.id
                                    ? 'bg-gray-700'
                                    : 'hover:bg-gray-800'
                            }`}
                            onClick={() => {
                                onChannelClick(sub);
                                if (isMobile) setIsMenuOpen(false);
                            }}
                        >
                            <img
                                src={getThumbnailUrl(sub)}
                                alt={sub.title || 'Channel'}
                                className="w-8 h-8 rounded-full mr-2"
                            />
                            <span className="text-sm truncate">{sub.title || 'Untitled Channel'}</span>
                        </div>
                    ))}
                    {channels.length > INITIAL_FETCH_COUNT && (
                        <button
                            onClick={showAllSubscriptions ? () => setShowAllSubscriptions(false) : handleLoadMore}
                            className="flex items-center justify-center w-full text-sm text-gray-400 hover:text-white mt-2 py-2 bg-gray-800 rounded"
                            disabled={isLoadingMoreChannels}
                        >
                            {isLoadingMoreChannels ? (
                                "Loading..."
                            ) : showAllSubscriptions ? (
                                <>
                                    Show less <ChevronUp className="ml-1 w-4 h-4" />
                                </>
                            ) : (
                                <>
                                    Show more <ChevronDown className="ml-1 w-4 h-4" />
                                </>
                            )}
                        </button>
                    )}
                    {loadMoreChannelsError && (
                        <p className="text-red-500 text-sm mt-2">{loadMoreChannelsError}</p>
                    )}
                </div>
            )}
        </>
    );

    const AuthButton = () => (
        user ? (
            <button
                onClick={() => {
                    onSignOut();
                    if (isMobile) setIsMenuOpen(false);
                }}
                className="flex items-center text-red-400 hover:text-red-300"
            >
                <LogOut className="w-4 h-4 mr-2" />
                Log Out
            </button>
        ) : (
            <button
                onClick={() => {
                    onSignIn();
                    if (isMobile) setIsMenuOpen(false);
                }}
                className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                <LogIn className="w-4 h-4 mr-2" />
                Sign in
            </button>
        )
    );

    if (isMobile) {
        return (
            <>
                <nav className="fixed top-0 left-0 right-0 bg-gray-800 border-b border-gray-700 z-50">
                    <div className="flex justify-between items-center h-16 px-4">
                        <h2 className="text-xl font-semibold text-white">YouTube TR</h2>
                        <div className="flex items-center">
                            <AuthButton />
                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="text-gray-300 hover:bg-gray-700 p-2 rounded-lg ml-4"
                            >
                                {isMenuOpen ? (
                                    <span className="text-2xl font-bold">×</span>
                                ) : (
                                    <Menu className="w-6 h-6" />
                                )}
                            </button>
                        </div>
                    </div>
                </nav>
                {isMenuOpen && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-40 mt-16">
                        <div className="flex flex-col h-full overflow-y-auto">
                            <div className="flex-grow p-4">
                                <NavContent />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <nav className="w-64 bg-gray-900 text-white h-screen flex flex-col">
            <div className="p-4 border-b border-gray-700 flex justify-between items-center">
                <h2 className="text-xl font-semibold">YouTube TR</h2>
                <AuthButton />
            </div>
            <div className="flex-grow overflow-y-auto p-4">
                <NavContent />
            </div>
        </nav>
    );
};

export default ResponsiveNavigation;