// useAuth.js
import { useState, useEffect } from 'react';
import { auth, googleProvider } from './firebaseConfig';
import { signInWithPopup, signOut } from 'firebase/auth';
import { storeUserData } from './firebaseFunctions';

export const useAuth = () => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);
        return () => unsubscribe();
    }, []);

    const handleAuthStateChange = async (user) => {
        if (user) {
            await storeUserData(user);
            // We can't get the OAuth token here, so we'll need to rely on the stored token
            const storedToken = localStorage.getItem('youtubeAccessToken');
            setToken(storedToken);
            setUser(user);
        } else {
            setUser(null);
            setToken(null);
            localStorage.removeItem('youtubeAccessToken');
        }
        setLoading(false);
    };

    const signIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const oauthAccessToken = result._tokenResponse.oauthAccessToken;
            localStorage.setItem('youtubeAccessToken', oauthAccessToken);
            setToken(oauthAccessToken);
            setUser(result.user);
        } catch (error) {
            console.error("Error during sign-in: ", error);
        }
    };

    const signOutUser = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setToken(null);
            localStorage.removeItem('youtubeAccessToken');
        } catch (error) {
            console.error("Error during sign-out: ", error);
        }
    };


    return { user, token, loading, signIn, signOutUser };
};