// src/firebaseConfig.js
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBMGzQNnPtKgiGj8xx99USML8P9UY4l8cs",
    authDomain: "path2excellence-cc6c8.firebaseapp.com",
    projectId: "path2excellence-cc6c8",
    storageBucket: "path2excellence-cc6c8.appspot.com",
    messagingSenderId: "491319932740",
    appId: "1:491319932740:web:f9b4b9fbfaead909478911"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

// Initialize Firebase Authentication
const auth = getAuth(app);


// Create a Google Auth Provider with the required scopes
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/youtube.readonly');
googleProvider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');

export { auth, googleProvider, db };