import { db, realtimeDb } from './firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, deleteDoc, collection, addDoc, query, where, getDocs, arrayUnion, arrayRemove, orderBy, limit, writeBatch, runTransaction, serverTimestamp } from 'firebase/firestore';

import { ref, set, onValue } from 'firebase/database';
import axios from "axios";

const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY_2;
const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
const BASE_URL = 'https://www.googleapis.com/youtube/v3/search';
const INITIAL_FETCH_COUNT = 5;


export const storeUserData = async (user) => {
    try {
        await setDoc(doc(db, 'Users', user.uid), {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            lastLogin: new Date(),
        }, { merge: true });
    } catch (error) {
        console.error('Error storing user data:', error);
        throw error;
    }
};

/*
export const fetchSubscriptions = async (token, userId, signOutUser) => {
    try {
        console.log("fetchSubscriptions : userId:" + userId + " token:" + token);
        const userDoc = await getDoc(doc(db, 'Users', userId));
        const userData = userDoc.data();

        if (userData?.Subscriptions && Date.now() - userData.lastSubscriptionFetch.toDate() < CACHE_DURATION) {
            return userData.Subscriptions;
        }

        const response = await fetch(
            'https://www.googleapis.com/youtube/v3/subscriptions?part=snippet&mine=true&maxResults=10',
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json'
                },
            }
        );

        if (response.status === 401) {
            // Token geçersiz, kullanıcıyı oturumdan çıkar
            await signOutUser();
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const fetchedSubscriptions = data.items || [];

        const processedChannels = fetchedSubscriptions.map(item => {
            // Her öğe için bir loglama fonksiyonu
            const logUndefined = (key, value) => {
                if (value === undefined) {
                    console.log(`Undefined value for ${key} in item:`, item);
                }
            };

            // Güvenli bir şekilde nesne özelliklerine erişim için yardımcı fonksiyon
            const safeGet = (obj, path, defaultValue = undefined) => {
                return path.split('.').reduce((acc, part) => (acc && acc[part] !== undefined) ? acc[part] : defaultValue, obj);
            };

            // Her alan için kontrol ve loglama
            const id = safeGet(item, 'id.videoId');
            logUndefined('id', id);

            const channelId = safeGet(item, 'snippet.resourceId.channelId');
            logUndefined('channelId', channelId);

            const title = safeGet(item, 'snippet.title');
            logUndefined('title', title);

            const description = safeGet(item, 'snippet.description');
            logUndefined('description', description);

            const thumbnailDefaultUrl = safeGet(item, 'snippet.thumbnails.default.url');
            logUndefined('thumbnailDefaultUrl', thumbnailDefaultUrl);

            const thumbnailMediumUrl = safeGet(item, 'snippet.thumbnails.medium.url');
            logUndefined('thumbnailMediumUrl', thumbnailMediumUrl);

            const publishedAt = safeGet(item, 'snippet.publishedAt');
            logUndefined('publishedAt', publishedAt);

            return {
                id: id || '',
                channelId: channelId || '',
                title: title || '',
                description: description || '',
                thumbnailDefaultUrl: thumbnailDefaultUrl || '',
                thumbnailMediumUrl: thumbnailMediumUrl || '',
                publishedAt: publishedAt ? new Date(publishedAt) : null,
                lastFetch: new Date()
            };
        });

        // İşlenmiş kanalları kontrol et
        console.log('Processed Channels:', processedChannels);
        await setDoc(doc(db, 'Users', userId), {
            Channels: processedChannels,
            lastSubscriptionFetch: new Date()
        }, { merge: true });

        return fetchedSubscriptions;
    } catch (error) {
        console.error("Error fetching YouTube subscriptions: ", error);
        throw error;
        return [];
    }
};


 */
export const fetchLatestVideos = async (token, userId, channelId, signOutUser) => {
    try {
        let videosQuery;
        console.log(" fetchLatestVideos -> selectedSubscriptionId: " + channelId);

        if (channelId) {

            //channelId = await getChannelIdFromChannelId(userId, channelId);
            console.log("method channelId: " + channelId);
            if (!channelId) {
                throw new Error('Invalid channelId');
            }

            videosQuery = query(
                collection(db, 'Videos'),
                //where('userId', '==', userId),
                where('channelId', '==', channelId),
                orderBy('publishedAt', 'desc'),
                limit(20)
            );
        } else {
            channelId = 'all';
            videosQuery = query(
                collection(db, 'Videos'),
                where('userId', '==', userId),
                orderBy('publishedAt', 'desc'),
                limit(20)
            );
        }

        const querySnapshot = await getDocs(videosQuery);
        const videos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        console.log("videos length: " + videos.length);

        if (videos.length === 0 || Date.now() - videos[0].lastFetch.toDate > CACHE_DURATION * 2) {
            //const allVideos = await searchYouTubeVideos("ebeveyn baba aile", 20);
            //await putVideosToFirestoreBatch(allVideos);
            //return allVideos;
            //return await fetchAndStoreVideosFromYouTube(token, userId, videos[0].lastFetch.toDate, selectedSubscriptionId, signOutUser);
        }

        return videos;
    } catch (error) {
        console.error('Error fetching videos:', error);
        throw error;
    }
};

/*
const fetchAndStoreVideosFromYouTube = async (token, userId, publishedAfter, selectedSubscriptionId, signOutUser) => {
    try {

        console.log("fetchAndStoreVideosFromYouTube selectedSubscriptionId: " + selectedSubscriptionId);
        //son güncel video tarihinden sonrakileri çek. afterThan kullan
        //mümkünse tek bir istekte çek
        //veritabanında videolar kanal id ile birlikte tutulsun, farklı kişiler için yeni içerik çekilmesin.

        const allVideos = [];

        for (const sub of subsToFetch) {
            const channelId = sub.snippet.resourceId.channelId;
            console.log("fetchAndStoreVideosFromYouTube 3 channel : " + channelId);

            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${channelId}&type=video&order=datepublishedAfter=${publishedAfter}&maxResults=20&key=${YOUTUBE_API_KEY}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json'
                    },
                }
            );

            if (response.status === 401) {
                // Token geçersiz, kullanıcıyı oturumdan çıkar
                await signOutUser();
            }

            if (!response.ok) {
                console.error(`HTTP error! status: ${response.status} for channel: ${channelId}`);
                continue;
            }

            const data = await response.json();
            console.log("data count " + data.length);
            const processedVideos = data.items.map(item => ({
                id: item.id.videoId,
                title: item.snippet.title,
                description: item.snippet.description,
                thumbnailUrl: item.snippet.thumbnails.medium.url,
                channelTitle: item.snippet.channelTitle,
                publishedAt: new Date(item.snippet.publishedAt),
                userId: userId,
                channelId: channelId,
                lastFetch: new Date()
            }));

            allVideos.push(...processedVideos);

            // Store videos in Firestore
            const batch = writeBatch(db);
            for (const video of processedVideos) {
                const videoRef = doc(db, 'Videos', video.id);
                batch.set(videoRef, video, { merge: true });
            }
            await batch.commit();

            console.log(`Fetched and stored ${processedVideos.length} videos for channel: ${sub.snippet.title}`);
        }

        // Sort videos by publishedAt in descending order
        allVideos.sort((a, b) => b.publishedAt - a.publishedAt);

        return allVideos;
    } catch (error) {
        console.error('Error fetching and storing videos from YouTube:', error);
        throw error;
    }
};


 */
export const searchYouTubeVideos = async (keywords, maxResults = 20) => {
    let retries = 0;

    const combinedKeywords = Array.isArray(keywords) ? keywords.join(' ') : keywords;

    const makeRequest = async () => {
        try {
            const params = {
                part: 'snippet',
                maxResults: maxResults,
                q: combinedKeywords,
                type: 'video',
                order: 'date', //'viewCount', 'rating', 'date', 'relevance'
                key: YOUTUBE_API_KEY,
                safeSearch: 'moderate',
                relevanceLanguage: 'tr',
                //videoCategoryId: '28',  // Örnek: 28 bilim ve teknoloji kategorisi
                videoDefinition: 'high',
                videoEmbeddable: 'true',
                videoSyndicated: 'true',
                fields: 'items(id/videoId,snippet(publishedAt,channelId,channelTitle,title,description,thumbnails/medium))'
            };

            const response = await axios.get(BASE_URL, { params });

            return response.data.items.map(item => ({
                id: item.id.videoId,
                title: item.snippet.title,
                description: item.snippet.description,
                thumbnailUrl: item.snippet.thumbnails.medium.url,
                channelTitle: item.snippet.channelTitle,
                channelId: item.snippet.channelId,
                publishedAt: item.snippet.publishedAt,
                keywords: keywords
            }));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Sunucu cevap verdi ama hata kodu döndü
                    console.error('Sunucu hatası:', error.response.status, error.response.data);
                    if (error.response.status === 403) {
                        throw new Error('API kotası aşıldı. Lütfen daha sonra tekrar deneyin.');
                    }
                } else if (error.request) {
                    // İstek yapıldı ama cevap alınamadı
                    console.error('Ağ hatası:', error.message);
                    if (retries < 2) {
                        retries++;
                        console.log(`Yeniden deneniyor (${retries}/${2})...`);
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        return makeRequest();
                    }
                }
            }
            throw error;
        }
    };

    return makeRequest();
};

const putVideosToFirestoreBatch = async (processedVideos) => {
    // Store videos in Firestore
    const batch = writeBatch(db);
    for (const video of processedVideos) {
        const videoRef = doc(db, 'Videos', video.id);
        batch.set(videoRef, video, { merge: true });
    }
    await batch.commit();

    console.log(`Fetched and stored ${processedVideos.length} videos`);
}
// Fetch video data from Firestore
export const fetchVideo = async (videoId) => {
    try {
        const videoDoc = await getDoc(doc(db, 'Videos', videoId));
        if (videoDoc.exists()) {
            return { id: videoDoc.id, ...videoDoc.data() };
        } else {
            console.log('No such video!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching video:', error);
        throw error;
    }
};


// Fetch reflections for a specific video
export const fetchReflections = async (videoId) => {
    try {
        const reflectionsRef = collection(db, 'Reflections');
        const q = query(
            reflectionsRef,
            where('videoId', '==', videoId),
            orderBy('createdAt', 'desc')
        );
        console.info('fetchReflections reflection:');

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            answers: [] // We'll fetch answers separately for better performance
        }));

    } catch (error) {
        console.error('Error fetching reflections:', error);
        throw error;
    }
};

export const addReflection = async (videoId, question, userId) => {
    try {
        const newReflection = {
            videoId,
            question,
            createdBy: userId,
            createdAt: new Date()
        };
        const reflectionsRef = collection(db, 'Reflections');
        const docRef = await addDoc(reflectionsRef, newReflection);
        console.info('adding reflection:', docRef);

        return { id: docRef.id, ...newReflection };
    } catch (error) {
        console.error('Error adding reflection:', error);
        throw error;
    }
};

export const updateReflection = async (reflectionId, updatedData) => {
    const reflectionRef = doc(db, 'Reflections', reflectionId);
    await updateDoc(reflectionRef, updatedData);
};

export const deleteReflection = async (reflectionId) => {
    const reflectionRef = doc(db, 'Reflections', reflectionId);
    await deleteDoc(reflectionRef);
};
// Add a new answer to a reflection
export const addAnswer = async (videoId, reflectionId, answerText, userId) => {
    try {
        const newAnswer = {
            text: answerText,
            createdBy: userId,
            createdAt: new Date()
        };
        const docRef = await addDoc(collection(db, 'Reflections', reflectionId, 'Answers'), newAnswer);
        return { id: docRef.id, ...newAnswer };
    } catch (error) {
        console.error('Error adding answer:', error);
        throw error;
    }
};

// Fetch answers for a specific reflection
/*export const fetchAnswers = async (videoId, reflectionId) => {
    try {
        const answersRef = collection(db, 'Videos', videoId, 'Reflections', reflectionId, 'Answers');
        const q = query(answersRef, orderBy('createdAV', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Error fetching answers:', error);
        throw error;
    }
};

 */

export const fetchAnswers = async ( reflectionId) => {
    try {
        const answersRef = collection(db, 'Reflections', reflectionId, 'Answers');
        const q = query(answersRef, orderBy('createdAt', 'asc'));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error('Error fetching answers:', error);
        throw error;
    }
};

// Fetch chains for a specific video
export const fetchChains = async (videoId, userId) => {
    try {
        const chainsRef = collection(db, 'Chains');
        const q = query(chainsRef, where("videoId", "==", videoId));
        const querySnapshot = await getDocs(q);

        const chains = [];
        for (const chainDoc of querySnapshot.docs) {
            const chainData = chainDoc.data();
            console.log("Error fetching chainDoc: userId: " + userId + "   chainDoc.id: " + chainDoc.id);

            // Fetch the ChainProgress for this chain and user
            const progressRef = doc(db, 'ChainProgress', `${chainDoc.id}_${userId}`);

            const progressDoc = await getDoc(progressRef);

            const progressData = progressDoc.exists() ? progressDoc.data() : null;
            console.log("Error fetching progressData: ");

            chains.push({
                id: chainDoc.id,
                ...chainData,
                progress: progressData
            });
        }

        return chains;
    } catch (error) {
        console.error("Error fetching chains: ", error);
        throw error;
    }
};

// Zincir Oluşturma ve Yönetme Fonksiyonları
export const createChain = async (chainData, userId) => {
    try {
        const chainsRef = collection(db, 'Chains');
        const newChainRef = doc(chainsRef);

        const chainDocData = {
            ...chainData,
            id: newChainRef.id,
            createdBy: userId,
            createdAt: serverTimestamp(),
            members: [userId],  // Include the creator as the first member
            invitedMembers: [],
            currentStreak: 0,
            longestStreak: 0,
            lastCompletedDate: null,
            status: 'active'
        };

        await setDoc(newChainRef, chainDocData);

        const userRef = doc(db, 'Users', userId);
        await updateDoc(userRef, {
            createdChains: arrayUnion(newChainRef.id)
        });

        return newChainRef.id;
    } catch (error) {
        console.error('Error creating chain:', error);
        throw error;
    }
};

export const joinChain = async (chainId, userId) => {
    try {
        const chainRef = doc(db, 'Chains', chainId);
        const chainDoc = await getDoc(chainRef);

        if (!chainDoc.exists()) {
            throw new Error('Chain not found');
        }

        await updateDoc(chainRef, {
            members: arrayUnion(userId),
            invitedMembers: arrayRemove(userId)
        });
        console.log("after updateDoc chainRef");

        await updateDoc(doc(db, 'Users', userId), {
            assignedChains: arrayUnion(chainId)
        });
        console.log("after updateDoc Users");

        await addDoc(collection(db, 'ChainProgress'), {
            chainId,
            userId,
            startDate: serverTimestamp(),
            lastCompletedDate: null,
            completedDays: 0,
            currentStreak: 0,
            longestStreak: 0,
            status: 'active'
        });
        console.log("after addDoc ChainProgress");

    } catch (error) {
        console.error('Error joining chain:', error);
        throw error;
    }
};

export const inviteToChain = async (chainId, invitedUserId, invitingUserId) => {
    try {
        const chainRef = doc(db, 'Chains', chainId);
        const chainDoc = await getDoc(chainRef);

        if (!chainDoc.exists()) {
            throw new Error('Chain not found');
        }

        if (!chainDoc.data().members.includes(invitingUserId)) {
            throw new Error('You are not a member of this chain');
        }

        await updateDoc(chainRef, {
            invitedMembers: arrayUnion(invitedUserId)
        });

        // Burada davet bildirimi gönderme işlemi eklenebilir

    } catch (error) {
        console.error('Error inviting to chain:', error);
        throw error;
    }
};

// İlerleme Takip Fonksiyonları
export const recordDailyProgress = async (chainId, userId, completed) => {
    try {
        const date = new Date();
        date.setHours(0, 0, 0, 0);

        await addDoc(collection(db, 'DailyProgress'), {
            chainId,
            userId,
            date: date,
            completed
        });

        if (completed) {
            await updateChainProgress(chainId, userId);
        }

    } catch (error) {
        console.error('Error recording daily progress:', error);
        throw error;
    }
};

const updateChainProgress = async (chainId, userId) => {
    const progressRef = doc(db, 'ChainProgress', `${chainId}_${userId}`);
    const progressDoc = await getDoc(progressRef);

    if (progressDoc.exists()) {
        const data = progressDoc.data();
        const newCompletedDays = data.completedDays + 1;
        const newCurrentStreak = data.currentStreak + 1;
        const newLongestStreak = Math.max(data.longestStreak, newCurrentStreak);

        await updateDoc(progressRef, {
            completedDays: newCompletedDays,
            currentStreak: newCurrentStreak,
            longestStreak: newLongestStreak,
            lastCompletedDate: serverTimestamp()
        });

        // Zincir belgesini de güncelle
        await updateChainStatus(chainId);
    }
};

const updateChainStatus = async (chainId) => {
    const chainRef = doc(db, 'Chains', chainId);
    const chainDoc = await getDoc(chainRef);

    if (chainDoc.exists()) {
        const chainData = chainDoc.data();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (chainData.lastCompletedDate &&
            chainData.lastCompletedDate.toDate().getTime() === today.getTime()) {
            // Zincir bugün zaten tamamlanmış, güncelleme yapmaya gerek yok
            return;
        }

        const newCurrentStreak = chainData.currentStreak + 1;
        const newLongestStreak = Math.max(chainData.longestStreak, newCurrentStreak);

        await updateDoc(chainRef, {
            currentStreak: newCurrentStreak,
            longestStreak: newLongestStreak,
            lastCompletedDate: serverTimestamp()
        });
    }
};

// Sorgulama Fonksiyonları
export const getUserChains = async (userId) => {
    try {
        const userDoc = await getDoc(doc(db, 'Users', userId));
        const assignedChains = userDoc.data().assignedChains || [];

        const chainsQuery = query(collection(db, 'Chains'), where('id', 'in', assignedChains));
        const chainsSnapshot = await getDocs(chainsQuery);

        return chainsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('Error fetching user chains:', error);
        throw error;
    }
};

export const getChainProgress = async (chainId, userId) => {
    try {
        const progressRef = doc(db, 'ChainProgress', `${chainId}_${userId}`);
        const progressDoc = await getDoc(progressRef);

        if (progressDoc.exists()) {
            return { id: progressDoc.id, ...progressDoc.data() };
        } else {
            return null;
        }
    } catch (error) {
        console.error('Error fetching chain progress:', error);
        throw error;
    }
};
// Submit daily feedback for a chain
export const submitChainFeedback = async (chainId, userId, completed) => {
    try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const progressRef = doc(db, 'ChainProgress', userId, chainId);
        const progressDoc = await getDoc(progressRef);

        if (progressDoc.exists()) {
            const data = progressDoc.data();
            if (completed) {
                await updateDoc(progressRef, {
                    lastCompletedDate: today,
                    completedDays: data.completedDays + 1
                });
            }
        }

        // Use Realtime Database for real-time updates
        const feedbackRef = ref(realtimeDb, `chainFeedback/${chainId}/${userId}/${today.toISOString().split('T')[0]}`);
        await set(feedbackRef, {
            completed,
            timestamp: new Date().toISOString()
        });
    } catch (error) {
        console.error('Error submitting chain feedback:', error);
        throw error;
    }
};

// Listen for real-time updates on chain feedback
export const listenToChainFeedback = (chainId, callback) => {
    const feedbackRef = ref(realtimeDb, `chainFeedback/${chainId}`);
    return onValue(feedbackRef, (snapshot) => {
        const feedbackData = snapshot.val();
        callback(feedbackData);
    });
};
