import { useQuery, useQueryClient } from 'react-query';
import { fetchUserChannels, loadMoreChannels } from './channelService';

export const useChannels = (userId, token) => {
    const queryClient = useQueryClient();

    const fetchChannelsQuery = useQuery(
        ['channels', userId],
        () => fetchUserChannels(userId, token),
        {
            enabled: !!userId,
            staleTime: 24 * 60 * 60 * 1000, // 24 hours
        }
    );

    const loadMore = async () => {
        if (!userId || !token) return;

        const currentData = queryClient.getQueryData(['channels', userId]);
        if (!currentData) return;

        const newData = await loadMoreChannels(token, userId, currentData.nextPageToken);

        queryClient.setQueryData(['channels', userId], old => {
            // Ensure old is an object and has a channels array
            const oldChannels = Array.isArray(old) ? old : (old?.channels || []);
            const newChannels = Array.isArray(newData) ? newData : (newData?.channels || []);

            return {
                channels: [...oldChannels, ...newChannels],
                nextPageToken: newData.nextPageToken
            };
        });

        return newData;
    };

    const refresh = async () => {
        if (!userId) return;
        return queryClient.invalidateQueries(['channels', userId]);
    };

    return {
        ...fetchChannelsQuery,
        loadMore,
        refresh,
    };
};