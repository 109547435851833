import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';

const VIDEOS_PER_CHANNEL = 5;

export const fetchHomeFeedVideos = async (userId, subscriptions) => {
    try {
        const allVideos = [];

        for (const subscription of subscriptions) {
            const videosQuery = query(
                collection(db, 'Videos'),
                where('channelId', '==', subscription.id),
                orderBy('publishedAt', 'desc'),
                limit(VIDEOS_PER_CHANNEL)
            );

            const querySnapshot = await getDocs(videosQuery);
            const videos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            allVideos.push(...videos);
        }

        // Sort all videos by publishedAt in descending order
        allVideos.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

        return allVideos;
    } catch (error) {
        console.error("Error fetching home feed videos: ", error);
        throw error;
    }
};