import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaPlay, FaThinkPeaks, FaShareAlt } from 'react-icons/fa';
import { auth } from './firebaseConfig';
import { fetchVideo } from './firebaseFunctions';
import ReflectionsSection from "./ReflectionsSection";
import ChainsSection from "./ChainsSection";
import { useReflections } from './useReflections';
import { useChains } from './useChains';

const stages = [
    { id: 'watch', icon: FaPlay, label: 'Watch' },
    { id: 'think', icon: FaThinkPeaks, label: 'Think' },
    { id: 'act', icon: FaShareAlt, label: 'Act' },
];

const VideoExperience = () => {
    const { videoId } = useParams();
    const navigate = useNavigate();
    const [currentStage, setCurrentStage] = useState('watch');
    const [video, setVideo] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { reflections, loading: loadingReflections, error: reflectionsError } = useReflections(videoId);
    const { chains, loading: loadingChains, error: chainsError } = useChains(videoId);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const loadVideo = async () => {
            try {
                setLoading(true);
                const videoData = await fetchVideo(videoId);
                setVideo(videoData);
            } catch (err) {
                setError('Failed to load video. Please try again.');
                console.error('Error loading video:', err);
            } finally {
                setLoading(false);
            }
        };
        loadVideo();
    }, [videoId]);

    const handleStageChange = (stage) => {
        setCurrentStage(stage);
    };

    if (loading) return <div className="flex justify-center items-center h-screen">Loading video...</div>;
    if (error) return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
    if (!video) return <div className="flex justify-center items-center h-screen">Video not found</div>;

    return (
        <div className="flex min-h-screen bg-gray-100">
            <div className="flex-1 flex flex-col">
                {/* Progress Bar */}
                <div className="bg-white shadow">
                    <div className="container mx-auto px-4">
                        <div className="flex justify-between items-center py-4">
                            {stages.map((stage) => (
                                <button
                                    key={stage.id}
                                    onClick={() => handleStageChange(stage.id)}
                                    className={`flex flex-col items-center ${
                                        currentStage === stage.id ? 'text-blue-500' : 'text-gray-500'
                                    }`}
                                >
                                    <stage.icon className="text-2xl mb-1"/>
                                    <span className="text-sm">{stage.label}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Content Area */}
                <div className="flex-grow overflow-y-auto">
                    <div className="container mx-auto px-4 py-8">
                        {currentStage === 'watch' && (
                            <div className="max-w-7xl mx-auto">
                                <div className="relative pt-[56.25%] overflow-hidden rounded-lg shadow-lg">
                                    <iframe
                                        src={`https://www.youtube.com/embed/${video.id}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="absolute inset-0 w-full h-full"
                                    ></iframe>
                                </div>
                            </div>
                        )}

                        {currentStage === 'think' && user && (
                            <ReflectionsSection
                                videoId={videoId}
                                reflections={reflections}
                                loading={loadingReflections}
                                error={reflectionsError}
                            />
                        )}

                        {currentStage === 'act' && user && (
                            <ChainsSection
                                videoId={videoId}
                                chains={chains}
                                loading={loadingChains}
                                error={chainsError}
                            />
                        )}
                    </div>
                </div>

                {/* Navigation Buttons */}
                <div className="bg-white shadow-md mt-auto">
                    <div className="container mx-auto px-4 py-4 flex justify-between">
                        <button
                            onClick={() => navigate(-1)}
                            className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
                        >
                            Back
                        </button>
                        {currentStage !== 'act' && (
                            <button
                                onClick={() => handleStageChange(stages[stages.findIndex(s => s.id === currentStage) + 1].id)}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Next
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoExperience;