import React from 'react';
import { useNavigate } from 'react-router-dom';


const VideoGrid = ({ videos, selectedChannel, isLoading, error }) => {
    const navigate = useNavigate();

    console.log("VideoGrid videos - " + videos.length);

    function timeAgo(publishedAt) {
        const now = new Date();
        const publishedDate = new Date(publishedAt);
        const timeDifference = now - publishedDate;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const years = Math.floor(days / 365);

        if (years > 0) {
            return years === 1 ? "1 year ago" : `${years} years ago`;
        } else if (weeks > 0) {
            return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
        } else if (days > 0) {
            return days === 1 ? "1 day ago" : `${days} days ago`;
        } else if (hours > 0) {
            return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
        } else if (minutes > 0) {
            return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
        } else {
            return seconds <= 1 ? "Just now" : `${seconds} seconds ago`;
        }
    }

    const handleVideoClick = (video) => {
        if (video && video.id) {
            console.log("handleVideoClick -> video.id: " + video.id);
            navigate(`/video/${video.id}`);
        } else {
            console.error('Invalid video object:', video);
        }
    };

    return (
        <div className="flex-1 min-h-full">
            <h1 className="text-2xl font-bold mb-6">
                {selectedChannel ? `Latest Videos from ${selectedChannel.title}` : 'Latest Videos'}
            </h1>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                     role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline">{error}</span>
                </div>
            )}
            {videos.length === 0 && !isLoading ? (
                <div className="text-center text-gray-500">
                    No videos available. Try selecting a channel or signing in again.
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 gap-4 sm:gap-6">
                    {videos.map((video) => (
                        <div key={video.id || `video-${Math.random()}`}
                             className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full"
                             onClick={() => handleVideoClick(video)}>
                            <div className="relative pt-[56.25%]">
                                <img
                                    src={video.thumbnailUrl}
                                    alt={video.title}
                                    className="absolute top-0 left-0 w-full h-full object-cover"
                                />
                            </div>
                            <div className="p-2 sm:p-3 md:p-4 flex flex-col justify-between flex-grow">
                                <div>
                                    <h2 className="text-sm sm:text-base font-semibold mb-1 sm:mb-2 line-clamp-2">{video.title}</h2>
                                    <p className="text-xs sm:text-sm text-gray-600">{video.channelTitle}</p>
                                </div>
                                <p className="text-xs text-gray-500 mt-1">
                                    {video.publishedAt ? timeAgo(video.publishedAt) : 'Unknown date'}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


export default VideoGrid;