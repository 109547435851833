import { useQuery } from 'react-query';
import { fetchHomeFeedVideos } from './homeFeedService';

export const useHomeFeed = (userId, subscriptions) => {
    return useQuery(
        ['homeFeed', userId],
        () => fetchHomeFeedVideos(userId, subscriptions),
        {
            enabled: !!userId && subscriptions?.length > 0,
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    );
};