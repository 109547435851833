import React, { useState } from 'react';
import { PlusIcon, StarIcon, UserIcon, CalendarIcon, ClockIcon, XIcon } from 'lucide-react';
import { addDoc, collection, updateDoc, doc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';

export default function ChainsSection({ videoId, chains, loading, error, currentUser }) {
    const [showNewChainForm, setShowNewChainForm] = useState(false);
    const [newChain, setNewChain] = useState({ title: '', description: '', duration: 7 });

    const handleNewChain = async (e) => {
        e.preventDefault();
        if (newChain.title && newChain.description) {
            try {
                await addDoc(collection(db, 'chains'), {
                    videoId,
                    ...newChain,
                    creator: currentUser.uid,
                    creatorName: currentUser.displayName,
                    participants: [currentUser.uid],
                    startDate: serverTimestamp(),
                    rating: 0,
                    raters: 0,
                    createdAt: serverTimestamp(),
                });
                setNewChain({ title: '', description: '', duration: 7 });
                setShowNewChainForm(false);
            } catch (err) {
                console.error("Error adding new chain: ", err);
                alert("Failed to create new chain. Please try again.");
            }
        }
    };

    const handleJoinChain = async (chainId) => {
        try {
            await updateDoc(doc(db, 'chains', chainId), {
                participants: arrayUnion(currentUser.uid)
            });
        } catch (err) {
            console.error("Error joining chain: ", err);
            alert("Failed to join the chain. Please try again.");
        }
    };

    if (loading) return <div className="flex justify-center items-center h-64">Loading chains...</div>;
    if (error) return <div className="text-red-500 text-center">Error loading chains: {error.message}</div>;

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Hayat Dersleri: Act</h1>

            {/* Create New Chain Button */}
            <button
                onClick={() => setShowNewChainForm(true)}
                className="mb-4 flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
            >
                <PlusIcon size={20} className="mr-2" />
                Create New Chain
            </button>

            {/* New Chain Form Modal */}
            {showNewChainForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Create New Chain</h2>
                            <button onClick={() => setShowNewChainForm(false)} className="text-gray-500 hover:text-gray-700">
                                <XIcon size={24} />
                            </button>
                        </div>
                        <form onSubmit={handleNewChain}>
                            <input
                                type="text"
                                value={newChain.title}
                                onChange={(e) => setNewChain({ ...newChain, title: e.target.value })}
                                placeholder="Chain Title"
                                className="w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                required
                            />
                            <textarea
                                value={newChain.description}
                                onChange={(e) => setNewChain({ ...newChain, description: e.target.value })}
                                placeholder="Chain Description"
                                className="w-full p-2 mb-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                rows={3}
                                required
                            />
                            <div className="flex items-center mb-2">
                                <label htmlFor="duration" className="mr-2">Duration (days):</label>
                                <input
                                    type="number"
                                    id="duration"
                                    value={newChain.duration}
                                    onChange={(e) => setNewChain({ ...newChain, duration: parseInt(e.target.value) })}
                                    min="1"
                                    className="w-20 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                            >
                                Create Chain
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {/* Chains List */}
            <div className="space-y-6">
                {chains.length === 0 ? (
                    <p className="text-center text-gray-500">No chains available for this video. Be the first to create one!</p>
                ) : (
                    chains.map((chain) => (
                        <div key={chain.id} className="bg-white p-4 rounded-lg shadow">
                            <div className="flex justify-between items-start mb-2">
                                <h3 className="text-xl font-semibold">{chain.title}</h3>
                                <div className="flex items-center">
                                    <StarIcon size={16} className="text-yellow-400 mr-1" />
                                    <span>{chain.rating.toFixed(1)} ({chain.raters})</span>
                                </div>
                            </div>
                            <p className="mb-4 text-gray-600">{chain.description}</p>
                            <div className="grid grid-cols-2 gap-2 mb-4">
                                <div className="flex items-center text-sm text-gray-500">
                                    <UserIcon size={16} className="mr-1" />
                                    <span>{chain.participants.length} participants</span>
                                </div>
                                <div className="flex items-center text-sm text-gray-500">
                                    <CalendarIcon size={16} className="mr-1" />
                                    <span>Started: {chain.startDate.toDate().toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center text-sm text-gray-500">
                                    <ClockIcon size={16} className="mr-1" />
                                    <span>Duration: {chain.duration} days</span>
                                </div>
                                <div className="flex items-center text-sm text-gray-500">
                                    <UserIcon size={16} className="mr-1" />
                                    <span>Creator: {chain.creatorName}</span>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                {chain.participants.includes(currentUser.uid) ? (
                                    <span className="text-green-600 font-semibold">You've joined this chain</span>
                                ) : (
                                    <button
                                        onClick={() => handleJoinChain(chain.id)}
                                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                                    >
                                        Join Chain
                                    </button>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}