import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, XIcon } from 'lucide-react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebaseConfig';

export default function ReflectionsSection({ videoId, reflections, loading, error }) {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [personalReflection, setPersonalReflection] = useState('');
    const [showAnswers, setShowAnswers] = useState({});
    const [newQuestion, setNewQuestion] = useState('');
    const [showNewQuestionForm, setShowNewQuestionForm] = useState(false);

    const handleQuestionSelect = (questionId) => {
        setSelectedQuestion(questionId);
        setPersonalReflection('');
    };

    const handleCloseModal = () => {
        setSelectedQuestion(null);
        setPersonalReflection('');
    };

    const toggleAnswers = (questionId) => {
        setShowAnswers(prev => ({ ...prev, [questionId]: !prev[questionId] }));
    };

    const handleSaveReflection = async () => {
        if (!personalReflection.trim()) return;

        try {
            await addDoc(collection(db, 'reflections'), {
                videoId,
                questionId: selectedQuestion,
                content: personalReflection,
                createdAt: new Date(),
                // Add user information here if available
            });
            handleCloseModal();
        } catch (err) {
            console.error("Error saving reflection: ", err);
            // Handle error (e.g., show error message to user)
        }
    };

    const handleAddNewQuestion = async (e) => {
        e.preventDefault();
        if (!newQuestion.trim()) return;

        try {
            await addDoc(collection(db, 'reflectionQuestions'), {
                videoId,
                question: newQuestion,
                createdAt: new Date(),
            });
            setNewQuestion('');
            setShowNewQuestionForm(false);
        } catch (err) {
            console.error("Error adding new question: ", err);
            // Handle error (e.g., show error message to user)
        }
    };

    if (loading) return <div>Loading reflections...</div>;
    if (error) return <div>Error loading reflections: {error.message}</div>;

    // Group reflections by question
    const reflectionsByQuestion = reflections.reduce((acc, reflection) => {
        if (!acc[reflection.questionId]) {
            acc[reflection.questionId] = [];
        }
        acc[reflection.questionId].push(reflection);
        return acc;
    }, {});

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Hayat Dersleri: Think</h1>

            <div className="mb-4">
                <p className="text-gray-600">
                    Take a moment to reflect on the video content. Choose a question below that resonates with you and spend some time thinking deeply about it.
                </p>
            </div>

            {/* Reflective Questions List */}
            <div className="space-y-4">
                {Object.entries(reflectionsByQuestion).map(([questionId, answers]) => (
                    <div key={questionId} className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-2">{answers[0].question}</h3>
                        <div className="flex justify-between items-center">
                            <button
                                onClick={() => handleQuestionSelect(questionId)}
                                className="text-blue-600 hover:underline"
                            >
                                Reflect on this
                            </button>
                            {answers.length > 0 && (
                                <button
                                    onClick={() => toggleAnswers(questionId)}
                                    className="flex items-center text-gray-600"
                                >
                                    {showAnswers[questionId] ? (
                                        <>
                                            Hide Answers <ChevronUpIcon size={16} className="ml-1" />
                                        </>
                                    ) : (
                                        <>
                                            Show Answers ({answers.length}) <ChevronDownIcon size={16} className="ml-1" />
                                        </>
                                    )}
                                </button>
                            )}
                        </div>
                        {showAnswers[questionId] && (
                            <div className="mt-4 pl-4 border-l-2 border-gray-200">
                                {answers.map((answer) => (
                                    <div key={answer.id} className="mb-2">
                                        <p className="text-sm font-semibold">{answer.author || 'Anonymous'}</p>
                                        <p className="text-sm">{answer.content}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Add New Question */}
            {showNewQuestionForm ? (
                <form onSubmit={handleAddNewQuestion} className="mt-4">
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        placeholder="Enter your question"
                        className="w-full p-2 border rounded-md"
                    />
                    <div className="mt-2 flex justify-end">
                        <button
                            type="button"
                            onClick={() => setShowNewQuestionForm(false)}
                            className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                        >
                            Add Question
                        </button>
                    </div>
                </form>
            ) : (
                <button
                    onClick={() => setShowNewQuestionForm(true)}
                    className="mt-4 flex items-center text-gray-600 hover:text-gray-800"
                >
                    <PlusIcon size={16} className="mr-1" />
                    Suggest a new reflection question
                </button>
            )}

            {/* Reflection Modal */}
            {selectedQuestion && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-start mb-4">
                            <h2 className="text-xl font-semibold">Reflect Deeply</h2>
                            <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-700">
                                <XIcon size={24} />
                            </button>
                        </div>
                        <p className="mb-4 text-gray-700">
                            {reflectionsByQuestion[selectedQuestion][0].question}
                        </p>
                        <textarea
                            value={personalReflection}
                            onChange={(e) => setPersonalReflection(e.target.value)}
                            placeholder="Take your time to reflect on this question. How does it relate to your life experiences?"
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows={8}
                        />
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={handleSaveReflection}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
                            >
                                Save Reflection
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}