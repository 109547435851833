// useVideos.js

import { useInfiniteQuery } from 'react-query';
import { fetchVideos, refreshVideos } from './videoService';


export const useVideos = (userId, channelId, token) => {
    const videosQuery = useInfiniteQuery(
        ['videos', userId, channelId],
        ({ pageParam = 0 }) => fetchVideos(userId, channelId, token, pageParam),
        {
            getNextPageParam: (lastPage, allPages) => {
                console.log("getNextPageParam called", { lastPage, allPagesCount: allPages.length });
                return lastPage.nextPageParam;
            },
            enabled: !!userId && !!channelId && !!token,
        }
    );

    console.log("useVideos query result:", {
        isLoading: videosQuery.isLoading,
        isError: videosQuery.isError,
        error: videosQuery.error,
        hasNextPage: videosQuery.hasNextPage,
        isFetchingNextPage: videosQuery.isFetchingNextPage,
        pagesCount: videosQuery.data?.pages?.length,
        lastPageSource: videosQuery.data?.pages[videosQuery.data.pages.length - 1]?.source
    });

    const refresh = async () => {
        if (!userId || !channelId || !token) return;
        await refreshVideos(userId, channelId, token);
        videosQuery.refetch();
    };

    return {
        ...videosQuery,
        refresh,
    };
};