// channelService.js

import {doc, getDoc, setDoc, writeBatch} from 'firebase/firestore';
import { db } from './firebaseConfig';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const INITIAL_FETCH_COUNT = 5;
const API_ERROR_COOLDOWN = 10 * 60 * 1000; // 10 minutes in milliseconds

const getFromLocalStorage = (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
};

const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const canMakeAPICall = (userId) => {
    const lastErrorTime = getFromLocalStorage(`youtube_api_error_${userId}`);
    if (lastErrorTime && Date.now() - lastErrorTime < API_ERROR_COOLDOWN) {
        console.log('API call blocked due to recent error');
        return false;
    }
    return true;
};

const setAPIErrorTime = (userId) => {
    setToLocalStorage(`youtube_api_error_${userId}`, Date.now());
};

const fetchFromYouTubeAPI = async (token, userId, pageToken = '') => {
    if (!canMakeAPICall(userId)) {
        throw new Error('API calls are temporarily disabled due to recent errors');
    }

    const url = `https://www.googleapis.com/youtube/v3/subscriptions?part=snippet&mine=true&maxResults=${INITIAL_FETCH_COUNT}${pageToken ? `&pageToken=${pageToken}` : ''}`;

    try {
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                throw new Error('UNAUTHORIZED');
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        setAPIErrorTime(userId);
        throw error;
    }
};


const processChannels = (items) => {
    return items.map(item => ({
        id: item.snippet.resourceId.channelId,
        title: item.snippet.title,
        description: item.snippet.description,
        thumbnailDefaultUrl: item.snippet.thumbnails.default.url,
        thumbnailMediumUrl: item.snippet.thumbnails.medium.url,
        publishedAt: new Date(item.snippet.publishedAt).toISOString(),
    }));
};

const updateFirestore = async (userId, channels) => {
    await setDoc(doc(db, 'Users', userId), {
        assignedChannels: channels.map(channel => channel.id),
        lastChannelFetch: new Date()
    }, { merge: true });

    const batch = writeBatch(db);
    // Update or create Channel documents
    channels.forEach(channel => {
        const channelRef = doc(db, 'Channels', channel.id);
        batch.set(channelRef, channel, { merge: true });
    });
    await batch.commit();
};

export const fetchUserChannels = async (userId, token) => {
    try {
        // Check local storage first
        const cachedData = getFromLocalStorage(`channels_${userId}`);
        if (cachedData && (Date.now() - cachedData.timestamp < CACHE_DURATION)) {
            console.log('Returning channels from local storage');
            return cachedData.channels;
        }

        // If not in local storage or cache is old, check Firestore
        console.log('Fetching channels from Firestore');
        const userDoc = await getDoc(doc(db, 'Users', userId));
        const userData = userDoc.data();

        if (userData?.assignedChannels && (Date.now() - userData.lastChannelFetch.toDate() < CACHE_DURATION)) {
            const channels = await fetchChannelDetails(userData.assignedChannels);
            setToLocalStorage(`channels_${userId}`, { channels, timestamp: Date.now() });
            return channels;
        }


        // If not in Firestore or cache is old, fetch from YouTube API
        if (canMakeAPICall(userId)) {
            console.log('Fetching channels from YouTube API');
            const data = await fetchFromYouTubeAPI(token, userId);
            const channels = processChannels(data.items);
            // Update Firestore
            await updateFirestore(userId, channels);
            // Update local storage
            setToLocalStorage(`channels_${userId}`, { channels, timestamp: Date.now() });

            return channels;
        } else {
            console.log('Using potentially outdated data due to API limitation');
            return userData?.assignedChannels ? await fetchChannelDetails(userData.assignedChannels) : [];
        }
    } catch (error) {
        console.error("Error fetching user channels: ", error);
        throw error;
    }
};

const fetchChannelDetails = async (channelIds) => {
    const channelDocs = await Promise.all(channelIds.map(id => getDoc(doc(db, 'Channels', id))));
    return channelDocs.map(doc => ({id: doc.id, ...doc.data()})).filter(Boolean);
};

export const loadMoreChannels = async (token, userId, pageToken) => {
    try {
        // Check local storage first
        const cachedData = getFromLocalStorage(`channels_${userId}`);
        if (cachedData && cachedData.nextPageToken === pageToken) {
            console.log('Returning more channels from local storage');
            return {
                channels: cachedData.channels.slice(INITIAL_FETCH_COUNT),
                nextPageToken: cachedData.nextPageToken
            };
        }

        // If not in local storage, check Firestore
        const userDoc = await getDoc(doc(db, 'Users', userId));
        const userData = userDoc.data();

        if (userData?.assignedChannels && userData.nextPageToken === pageToken) {
            console.log('Returning more channels from Firestore');
            const allChannels = await fetchChannelDetails(userData.assignedChannels);
            const newChannels = allChannels.slice(INITIAL_FETCH_COUNT);
            return {
                channels: newChannels,
                nextPageToken: userData.nextPageToken
            };
        }

        // If not in Firestore or different page token, fetch from YouTube API
        if (canMakeAPICall(userId)) {
            console.log('Fetching more channels from YouTube API');
            const data = await fetchFromYouTubeAPI(token, userId, pageToken);
            const newChannels = processChannels(data.items);

            // Update local storage
            const updatedChannels = [...(cachedData?.channels || []), ...newChannels];
            setToLocalStorage(`channels_${userId}`, {
                channels: updatedChannels,
                timestamp: Date.now(),
                nextPageToken: data.nextPageToken
            });

            // Update Firestore
            await updateFirestore(userId, updatedChannels);
            await setDoc(doc(db, 'Users', userId), { nextPageToken: data.nextPageToken }, { merge: true });

            return {
                channels: newChannels,
                nextPageToken: data.nextPageToken
            };
        } else {
            console.log('Unable to load more channels due to API limitation');
            return { channels: [], nextPageToken: null };
        }
    } catch (error) {
        console.error("Error loading more channels: ", error);
        throw error;
    }
};

export const refreshChannels = async (userId, token) => {

    if (!canMakeAPICall(userId)) {
        console.log('Refresh blocked due to recent API error');
        return fetchUserChannels(userId, token); // Return potentially outdated data
    }
    // Clear local storage
    localStorage.removeItem(`channels_${userId}`);

    // Clear Firestore cache
    await setDoc(doc(db, 'Users', userId), {
        lastChannelFetch: new Date(0), // Set to a past date to force refresh
        nextPageToken: null
    }, { merge: true });

    // Fetch fresh data from YouTube API
    return fetchUserChannels(userId, token);
};